var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('KTPortlet',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.message !== null)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-text"},[_vm._v(" "+_vm._s(_vm.message)+" ")])]):_vm._e(),_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","show-empty":"","items":_vm.items,"fields":_vm.fields,"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("ASSIGNED_CLASSES.NO_CLASS_ASSIGNED"))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"pill":"","size":"sm","variant":"info","title":_vm.$t('GENERAL.UPLOAD_NEW_FILE'),"to":{
                  name: 'lecturerUploadNewClassMaterials',
                  params: { id: item.id }
                }}},[_c('i',{staticClass:"fa fa-file-upload",staticStyle:{"font-size":"1.3em","margin-left":"0"}})]),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"pill":"","size":"sm","variant":"warning","title":_vm.$t('GENERAL.UPLOAD_NEW_QUIZ'),"to":{
                  name: 'uploadNewQuiz',
                  params: { id: item.id }
                }}},[_c('i',{staticClass:"fas fa-tasks",staticStyle:{"font-size":"1.3em","margin-left":"0"}})]),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"pill":"","size":"sm","variant":"success","title":_vm.$t('GENERAL.UPLOAD_NEW_ASSIGNMENT'),"to":{
                  name: 'uploadNewAssignment',
                  params: { id: item.id }
                }}},[_c('i',{staticClass:"flaticon2-sheet",staticStyle:{"font-size":"1.3em","margin-left":"0"}})]),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"pill":"","size":"sm","variant":"dark","title":_vm.$t('MENU.ATTENDANCE'),"to":{
                  name: 'classAttendance',
                  params: { id: item.id }
                }}},[_c('i',{staticClass:"fa fa-list",staticStyle:{"font-size":"1.3em","margin-left":"0","color":"white"}})]),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"pill":"","size":"sm","variant":"success","title":_vm.$t('MENU.ATTENDANCE_REPORT'),"to":{
                  name: 'classAttendanceReport',
                  params: { id: item.id }
                }}},[_c('i',{staticClass:"fa fa-list",staticStyle:{"font-size":"1.3em","margin-left":"0","color":"white"}})]),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"pill":"","size":"sm","variant":"primary","title":_vm.$t('MENU.QUIZZES_REPORT'),"to":{
                  name: 'classQuizzesReport',
                  params: { id: item.id }
                }}},[_c('i',{staticClass:"fa fa-check-square",staticStyle:{"font-size":"1.3em","margin-left":"0","color":"white"}})]),_vm._v(" "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"pill":"","size":"sm","variant":"dark","title":_vm.$t('MENU.ASSIGNMENT_REPORT'),"to":{
                  name: 'classAssignmentsReport',
                  params: { id: item.id }
                }}},[_c('i',{staticClass:"fa fa-check-square",staticStyle:{"font-size":"1.3em","margin-left":"0","color":"white"}})])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v(_vm._s(_vm.$t("GENERAL.PLEASE_WAIT")))])],1)]},proxy:true}])})]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }