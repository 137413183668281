<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title">
          <template v-slot:body>
            <div
              v-if="message !== null"
              role="alert"
              class="alert alert-success"
            >
              <div class="alert-text">
                {{ message }}
              </div>
            </div>
            <b-table
              striped
              hover
              show-empty
              :items="items"
              :fields="fields"
              :busy="isBusy"
              class="mt-3"
            >
              <template v-slot:empty="scope">
                <p class="text-center">
                  {{ $t("ASSIGNED_CLASSES.NO_CLASS_ASSIGNED") }}
                </p>
              </template>
              <template v-slot:cell(actions)="{ item }">
                <b-button
                  pill
                  size="sm"
                  variant="info"
                  v-b-tooltip.hover
                  :title="$t('GENERAL.UPLOAD_NEW_FILE')"
                  :to="{
                    name: 'lecturerUploadNewClassMaterials',
                    params: { id: item.id }
                  }"
                >
                  <i
                    class="fa fa-file-upload"
                    style="font-size: 1.3em; margin-left: 0;"
                  ></i>
                </b-button>
                &nbsp;
                <b-button
                  pill
                  size="sm"
                  variant="warning"
                  v-b-tooltip.hover
                  :title="$t('GENERAL.UPLOAD_NEW_QUIZ')"
                  :to="{
                    name: 'uploadNewQuiz',
                    params: { id: item.id }
                  }"
                >
                  <i
                    class="fas fa-tasks"
                    style="font-size: 1.3em; margin-left: 0;"
                  ></i>
                </b-button>
                &nbsp;
                <b-button
                  pill
                  size="sm"
                  variant="success"
                  v-b-tooltip.hover
                  :title="$t('GENERAL.UPLOAD_NEW_ASSIGNMENT')"
                  :to="{
                    name: 'uploadNewAssignment',
                    params: { id: item.id }
                  }"
                >
                  <i
                    class="flaticon2-sheet"
                    style="font-size: 1.3em; margin-left: 0;"
                  ></i>
                </b-button>
                &nbsp;
                <b-button
                  pill
                  size="sm"
                  variant="dark"
                  v-b-tooltip.hover
                  :title="$t('MENU.ATTENDANCE')"
                  :to="{
                    name: 'classAttendance',
                    params: { id: item.id }
                  }"
                >
                  <i
                    class="fa fa-list"
                    style="font-size: 1.3em; margin-left: 0; color: white;"
                  ></i>
                </b-button>
                &nbsp;
                <b-button
                  pill
                  size="sm"
                  variant="success"
                  v-b-tooltip.hover
                  :title="$t('MENU.ATTENDANCE_REPORT')"
                  :to="{
                    name: 'classAttendanceReport',
                    params: { id: item.id }
                  }"
                >
                  <i
                    class="fa fa-list"
                    style="font-size: 1.3em; margin-left: 0; color: white;"
                  ></i>
                </b-button>
                &nbsp;
                <b-button
                  pill
                  size="sm"
                  variant="primary"
                  v-b-tooltip.hover
                  :title="$t('MENU.QUIZZES_REPORT')"
                  :to="{
                    name: 'classQuizzesReport',
                    params: { id: item.id }
                  }"
                >
                  <i
                    class="fa fa-check-square"
                    style="font-size: 1.3em; margin-left: 0; color: white;"
                  ></i>
                </b-button>
                &nbsp;
                <b-button
                  pill
                  size="sm"
                  variant="dark"
                  v-b-tooltip.hover
                  :title="$t('MENU.ASSIGNMENT_REPORT')"
                  :to="{
                    name: 'classAssignmentsReport',
                    params: { id: item.id }
                  }"
                >
                  <i
                    class="fa fa-check-square"
                    style="font-size: 1.3em; margin-left: 0; color: white;"
                  ></i>
                </b-button>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                </div>
              </template>
            </b-table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      isBusy: true,
      fields: [
        { faculty: this.$t("GENERAL.FACULTY") },
        { department: this.$t("GENERAL.DEPARTMENT") },
        { semester: this.$t("GENERAL.SEMESTER") },
        { class_name: this.$t("GENERAL.CLASS_NAME") },
        { class_time: this.$t("GENERAL.CLASS_TIME") },
        { subject: this.$t("GENERAL.SUBJECT") },
        { number_of_students: this.$t("GENERAL.NUMBER_OF_STUDENTS") },
        { actions: this.$t("GENERAL.ACTIONS") }
      ],
      items: [],
      title: this.$t("MENU.ASSIGNED_CLASSES"),
      message: null
    };
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.ASSIGNED_CLASSES"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get("lecturer/assigned-classes").then(({ data }) => {
      this.items = data.classes;
      this.title +=
        " " +
        this.$t("GENERAL.FOR_YEAR_TERM", { year: data.year, term: data.term });
      this.isBusy = false;
    });
  },
  created() {
    if (
      this.$route.params.message !== null &&
      this.$route.params.message !== undefined
    ) {
      this.message = this.$route.params.message;
    }
  }
};
</script>
